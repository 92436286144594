<!-- 仓库出库表-详情 -->
<template>
  <div class="pagePadding home">
    <Form inline class="formMarginBtm20">
      <!-- 产品名称 -->
      <FormItem>
        <span>产品名称：</span>
        <Select placeholder="请选择" v-model="queryFrom.product_id" @on-change="productChange" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in productList" :value="item.product_id" :key="index">{{ item.product_name }}</Option>
        </Select>
      </FormItem>
      <!-- 规格型号/SKU： -->
      <FormItem class="marginLeft60">
        <span>规格型号/SKU：</span>
        <Select placeholder="请选择" v-model="queryFrom.specification_id_str" :disabled="!queryFrom.product_id" clearable filterable multiple :max-tag-count="1" class="iviewIptWidth250">
          <Option v-for="(item, index) in skuList" :value="item.specification_id" :key="index" :label="item.model_name + '-' + item.item_number">
            <span>{{ item.model_name }}</span>
            <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
          </Option>
        </Select>
      </FormItem>
      <!-- 供应商 -->
      <FormItem class="marginLeft60">
        <span>供应商：</span>
        <Select placeholder="请输入" v-model="queryFrom.supplier_id" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in supplierList" :value="item.supplier_id" :key="index">{{ item.supplier_name }}</Option>
        </Select>
      </FormItem>
      <!-- 厂家 -->
      <FormItem class="marginLeft60">
        <span>厂家：</span>
        <Select placeholder="请输入" v-model="queryFrom.factory_id" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in factoryList" :value="item.factory_id" :key="index">{{ item.factory_name }}</Option>
        </Select>
      </FormItem>
      <!-- 出库时间 -->
      <FormItem>
        <span>出库时间：</span>
        <DatePicker type="date" placeholder="请选择" :value="queryFrom.create_time_start" @on-change="changeTime($event, 1)" class="iviewIptWidth250"></DatePicker>
        <span style="padding: 0 10px;">至</span>
        <DatePicker type="date" placeholder="请选择" :options="options" :value="queryFrom.create_time_end" @on-change="changeTime($event, 2)" class="iviewIptWidth250"></DatePicker>
      </FormItem>
      <!-- 供应商 -->
      <FormItem class="marginLeft60">
        <span>出库单号：</span>
        <!--        <Select placeholder="请输入" v-model="queryFrom.order_number" clearable filterable class="iviewIptWidth250">-->
        <!--          <Option v-for="(item, index) in outOrderList" :value="item.order_number" :key="index">{{ item.order_number }}</Option>-->
        <!--        </Select>-->
        <i-input placeholder="请输入" class="iviewIptWidth250" v-model="queryFrom.order_number"></i-input>
      </FormItem>
      <!-- 按钮 -->
      <FormItem class="po-create-number marginRight0" :label-width="10">
        <span class="pageBtn finger btnSure" @click="query">查询</span>
        <span class="pageBtn finger btnSure marginLeft20">
          <span v-if="!Loagin" class="pageBtn finger btnSure" @click="Export">导出</span>
          <span v-if="Loagin" class="pageBtn finger btnSure">
            <Spin v-if="Loagin" style="display: inline-block; color: white;">
              <Icon type="ios-loading" class="demo-spin-icon-load"></Icon>
            </Spin>
          </span>
        </span>
        <span class="pageBtn finger btnSure marginLeft20" @click="setupStatus = true">设置</span>
        <span class="pageBtn finger btnReset" @click="back" style="margin-left: 20px;">返回</span>
      </FormItem>
    </Form>

    <!-- 表格 + 分页 -->
    <div class="pages tabDiv" ref="tabDiv">
      <Table :height="tableHeight" :productList="listColumns" :productData="listData" :option_page="option_page" border show-summary :summary-method="handleSummary" class="table" :loading="loading" :isLoad="loading"></Table>
    </div>
    <div class="pagesBtm" v-if="listData.length != 0">
      <Page v-if="total <= 60" :total="total" :current="queryFrom.page" @on-change="changePage" class="fl page" />
      <Page v-if="total > 60" show-elevator :total="total" :current="queryFrom.page" @on-change="changePage" class="fl page" />
      <!-- <div class="fr totalFont page">共计{{ total }}条记录</div> -->
    </div>
    <!-- <img src="../../assets/images/tableNoData.png" v-else /> -->
    <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="listData.length == 0 && !loading">
      <img style="margin-top: 100px;" src="../../assets/images/tableNoData.png" />
    </div>
    <!-- 返回 -->
    <!-- <div class="pageBtm fr">
      <span class="pageBtn finger btnReset" @click="back">返回</span>
    </div> -->
    <!-- 动态表头弹窗 -->
    <table-setup v-if="setupStatus" :pageList="titleList" :option_page="option_page" @cancleBtn="setupStatus = false" @sureBrn="sureSetup"></table-setup>
  </div>
</template>

<script>
import Table from '@/components/table'
import TableSetup from '@/components/tableSetup'
export default {
  name: 'detailsWarehouseOutTable',
  components: {
    Table,
    TableSetup,
  },
  data() {
    return {
      Loagin: false,
      tableHeight: 0,
      outOrderList: [],
      totalQuantity: 0,
      totalAmount: 0,
      titleList: [],
      option_page: '25',
      setupStatus: false,
      loading: true,
      // 总条数
      total: 0,
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 75,
          key: 'index',
        },
        {
          title: '产品编号',
          key: 'product_model_code',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 250,
        },
        {
          title: '基本单位',
          key: 'unit',
          align: 'center',
          minWidth: 80,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '批号',
          key: 'batch_number',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '供应商',
          key: 'supplier_name',
          align: 'center',
          minWidth: 250,
        },
        {
          title: '厂家',
          key: 'factory_name',
          align: 'center',
          minWidth: 250,
        },
        {
          title: '出库数量',
          key: 'quantity',
          align: 'center',
          minWidth: 80,
        },
        {
          title: '出库金额',
          key: 'amount',
          align: 'center',
          minWidth: 80,
          render: (h, param) => {
            return h('span', this.$utils.formatMoney(param.row.amount, true))
          },
        },
      ],
      // 产品下拉
      productList: [],
      // SKU下拉
      skuList: [],
      // 供应商下拉
      supplierList: [],
      // 厂家下拉
      factoryList: [],
      // 查询参数
      queryFrom: {
        page: 1,
        rows: 10,
        warehouse_id: 0,

        product_model_code: '',
        product_id: 0,
        specification_id_str: '',
        supplier_id: 0,
        factory_id: 0,
        create_time_start: '',
        create_time_end: '',
      },
      options: {},
    }
  },
  created() {
    this.queryOption()
    this.queryFrom.warehouse_id = this.$route.query.warehouse_id
    this.queryFrom.create_time_start = this.$route.query.create_time_start
    this.queryFrom.create_time_end = this.$route.query.create_time_end
    this.warehouseOutDetail()
    this.getInventoryProductLookup()
    // this.getSupplierList()
    this.getListSupList()
    this.getwarehouseList()
  },
  methods: {
    // 导出
    Export() {
      if (this.Loagin) {
        return
      }
      this.Loagin = true
      let list = this.queryFrom.specification_id_str ? this.queryFrom.specification_id_str.join(',') : ''
      let data = {
        warehouse_id: this.$route.query.warehouse_id,
        product_model_code: this.queryFrom.product_model_code,
        product_id: this.queryFrom.product_id,
        specification_id_str: list,
        supplier_id: this.queryFrom.supplier_id,
        factory_id: this.queryFrom.factory_id,
        create_time_start: this.queryFrom.create_time_start,
        create_time_end: this.queryFrom.create_time_end,
      }
      this.$http.downFile(this.$api.getWarehouse_outDetail_export, data, false).then(res => {
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '仓库出库详情表.xlsx'
        aLink.click()
        this.Loagin = false
      })
    },
    getwarehouseList() {
      this.$http.get(this.$api.warehouseDeliverNumber, true).then(res => {
        this.outOrderList = res.data
      })
    },
    // 处理金额
    OpearateMoney(item) {
      let num = item
      num = parseFloat((num + '').replace(/[^\d\.-]/g, '')).toFixed(2) + ''
      var valueArr = num.split('.')[0].split('').reverse()
      const valueFloat = num.split('.')[1]
      let valueString = ''
      for (let i = 0; i < valueArr.length; i++) {
        valueString += valueArr[i] + ((i + 1) % 3 == 0 && i + 1 != valueArr.length ? ',' : '') // 循环 取数值并在每三位加个','
      }
      const money = valueString.split('').reverse().join('') + '.' + valueFloat // 拼接上小数位
      return '¥' + money
    },
    sureSetup(val) {
      this.queryOption()
    },
    // 获取表格表头
    queryOption() {
      this.$http
        .get(this.$api.systemOptionItem, { option_page: this.option_page })
        .then(res => {
          this.titleList = res.data.enable
          localStorage.setItem('adminTtitleList', JSON.stringify(res.data.list))
          for (let i = 0; i < this.titleList.length; i++) {
            if (this.titleList[i].show) {
              this.titleList[i].key = this.titleList[i].data_col
              this.titleList[i].width = this.titleList[i].column_width
              this.titleList[i].align = 'center'
              this.titleList[i].resizable = true
            }
          }
          this.listColumns = []
          this.listColumns.unshift({
            title: '序号',
            type: 'index',
            align: 'center',
            width: 60,
          })
          this.listColumns = [...this.listColumns, ...this.titleList]
          this.listColumns = JSON.parse(JSON.stringify(this.listColumns))
        })
        .then(() => {
          // this.queryStoreList()
        })
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        this.queryFrom.create_time_start = e
        // 清空截止日期
        if (e > this.queryFrom.create_time_end) {
          this.queryFrom.create_time_end = ''
        }
        this.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryFrom.create_time_start) - 86400000
          },
        }
      } else {
        this.queryFrom.create_time_end = e
      }
    },
    // 返回
    back() {
      this.$router.go(-1)
    },
    // 厂家下拉
    getListSupList() {
      this.$http.get(this.$api.warehouseOutDetailFactory, { warehouse_id: this.$route.query.warehouse_id }).then(res => {
        this.factoryList = res.data
        this.supplierList = res.supplier_info
      })
    },
    // 供应商下拉
    // getSupplierList() {
    //   this.$http.get(this.$api.supplierNameList).then(res => {
    //     this.supplierList = res.data
    //   })
    // },
    // 产品编号改变
    productChange(e) {
      this.skuList = []
      this.queryFrom.specification_id_str = ''
      if (!e) return
      // 规格/SKU下拉
      let data = {
        warehouse_id: this.queryFrom.warehouse_id,
        product_id: this.queryFrom.product_id,
      }
      this.$http.get(this.$api.inventoryCodeLookup, data).then(res => {
        this.skuList = res.data
      })
    },
    // 产品下拉
    getInventoryProductLookup() {
      this.$http.get(this.$api.inventoryProductLookup, { warehouse_id: this.queryFrom.warehouse_id }).then(res => {
        this.productList = res.data
      })
    },
    // 查询
    query() {
      this.queryFrom.page = 1
      if (this.queryFrom.product_model_code) {
        this.queryFrom.product_model_code = String(this.queryFrom.product_model_code)
      }
      let specification_id_str = this.queryFrom.specification_id_str
      if (this.queryFrom.specification_id_str) {
        this.queryFrom.specification_id_str = String(this.queryFrom.specification_id_str)
      }
      this.warehouseOutDetail()
      this.queryFrom.specification_id_str = specification_id_str
    },
    // 分页改变
    changePage(e) {
      this.queryFrom.page = e
      this.warehouseOutDetail()
    },
    // 获取详情数据
    warehouseOutDetail() {
      this.loading = true
      this.$http.get(this.$api.warehouseOutDetail, this.queryFrom, true).then(res => {
        this.listData = res.data
        this.totalQuantity = 0
        this.totalAmount = 0
        this.loading = false
        this.listData.forEach((item, index) => {
          this.totalQuantity = item.quantity + this.totalQuantity
          this.totalAmount = item.amount + this.totalAmount
          item.licence_valid_period = item.licence_valid_period ? this.$moment.unix(item.licence_valid_period).format('YYYY-MM-DD') : ''
          item.production_date = item.production_date ? this.$moment.unix(item.production_date).format('YYYY-MM-DD HH:mm:ss') : ''
          // 判断是录入的是年月还是年月日
          item.production_date = item.production_date ? this.$utils.Timetransformation(item.production_date) : ''
          item.valid_period = item.valid_period ? this.$moment.unix(item.valid_period).format('YYYY-MM-DD HH:mm:ss') : ''
          item.valid_period = item.valid_period ? this.$utils.Timetransformation(item.valid_period) : ''
          item.production_license_valid_period = item.production_license_valid_period ? this.$moment.unix(item.production_license_valid_period).format('YYYY-MM-DD') : ''
          item.production_record_date = item.production_record_date ? this.$moment.unix(item.production_record_date).format('YYYY-MM-DD') : ''
          if (item.business_license_valid_period && this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
            item.business_license_valid_period = item.business_license_valid_period ? this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') : ''
          } else if (item.business_license_valid_period && this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') == '9999-12-31') {
            item.business_license_valid_period = '长期'
          } else {
            item.business_license_valid_period = ''
          }
          if (item.record_valid_period && this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
            item.record_valid_period = item.record_valid_period ? this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') : ''
          } else if (item.record_valid_period && this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') == '9999-12-31') {
            item.record_valid_period = '长期'
          } else {
            item.record_valid_period = ''
          }
          item.return_time = item.return_time ? this.$moment.unix(item.return_time).format('YYYY-MM-DD') : ''
          item.receive_time = item.receive_time ? this.$moment.unix(item.receive_time).format('YYYY-MM-DD') : ''
          item.accept_time = item.accept_time ? this.$moment.unix(item.accept_time).format('YYYY-MM-DD') : ''
          item.rec_time = item.rec_time ? this.$moment.unix(item.rec_time).format('YYYY-MM-DD') : ''
          item.order_time = item.order_time ? this.$moment.unix(item.order_time).format('YYYY-MM-DD') : ''
          item.picking_time = item.picking_time ? this.$moment.unix(item.picking_time).format('YYYY-MM-DD') : ''
          item.delivery_time = item.delivery_time ? this.$moment.unix(item.delivery_time).format('YYYY-MM-DD') : ''
          item.amount = this.OpearateMoney(item.amount)
        })
        this.$nextTick(() => {
          this.tableHeight = this.$refs.tabDiv.offsetHeight - 50 + ''
          this.$forceUpdate()
        })
        this.totalAmount = this.OpearateMoney(this.totalAmount)
        this.total = res.total
      })
    },
    /**
     * 参数1：表头信息，即 listColumns
     * 参数2：每行数据，以行为单位
     * */
    handleSummary({ columns, data }) {
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计',
          }
          return
        }
        // 出库金额列加￥
        if (key === 'amount') {
          sums[key] = { key, value: this.totalAmount }
        } else if (key === 'quantity') {
          sums[key] = { key, value: this.totalQuantity }
        } else {
          sums[key] = { key, value: '' }
        }
      })
      return sums
    },
  },
}
</script>

<style scoped lang="less">
.home {
  display: flex;
  flex-direction: column;
}
.tabDiv {
  flex: 1;
  overflow-y: auto;
  height: auto;
}
.pageBtm {
  margin-top: 10px;
}

.pages {
  position: relative;
  // border: 1px solid #eee;
  .pagesBtm {
    overflow: hidden;
    line-height: 32px;
    .page {
      margin-top: 20px;
    }
  }
  img {
    margin-top: 100px;
    position: absolute;
    left: 50%;
    // transform: translateX(-50%);
  }
}

.table {
  /deep/ .ivu-table-summary {
    border-top: 0;
  }
}
</style>
